import styled from "styled-components";
import palette from "../../../lib/styles/palette";
import {useEffect, useState} from "react";

export type CourseInfoProps = {
    title: string;
    region: string;
    discountRate?: number;
    price: number;
};

// 천 단위 콤마를 처리하는 함수
const formatPrice = (price: number): string => {
    return Number(price).toLocaleString();
};


function CourseInfo(props: CourseInfoProps) {
    const { title, region, discountRate, price } = props;

    // 상태를 사용하여 가격 데이터를 관리
    const [formattedPrice, setFormattedPrice] = useState<string>("");
    // 가격 데이터가 변경될 때마다 포맷팅
    useEffect(() => {
        setFormattedPrice(formatPrice(price));
    }, [price]);


    return (
        <Block>
            <Title>{title}</Title>
            <Region>{region}</Region>
            <PriceBlock>
                {discountRate && <Discount>{discountRate}%</Discount>}
                <Price>
                    {formattedPrice} {/* 포맷팅된 가격 출력 */}
                    <span>원</span>
                </Price>
            </PriceBlock>
        </Block>
    );
}

const Block = styled.div`
    margin-bottom: 20px;
`;

const Title = styled.h1`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    word-break: keep-all;
`;

const Region = styled.p`
    font-size: 14px;
    font-weight: 700;
    color: ${palette.gray6};
    margin-top: 10px;
`;

const PriceBlock = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;

const Price = styled.p`
    font-size: 20px;
    font-weight: 800;

    span {
        font-size: 14px;
    }
`;

const Discount = styled.p`
    color: ${palette.red500};
    font-size: 20px;
    font-weight: 800;
    margin-right: 10px;
`;

export default CourseInfo;
